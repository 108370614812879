import React, { useState } from "react"
import { Tab } from "@headlessui/react"
import selfpublish from "../images/self_publish.png"
import publishThroughSiYO from "../images/publish-through-si-yo.png"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Tabs() {
  let [categories] = useState({
    "Self Publish": [
      {
        id: 1,
        path: selfpublish,
        alt: "Self Publish",
      },
    ],
    "Publish Through Si-Yo": [
      {
        id: 2,
        path: publishThroughSiYO,
        alt: "Publish Through Si-Yo",
      },
    ],
  })

  return (
    <div className=" max-w-screen-lg flex flex-col justify-center items-center px-2 py-0 sm:px-0 ">
      <Tab.Group>
        <Tab.List className="max-w-screen-sm  flex  p-1 space-x-1 bg-red-600 rounded-xl">
          {Object.keys(categories).map(category => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "w-screen py-2 text-sm leading-5 font-medium text-red-700 rounded-lg",
                  "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-red-400 ring-white ring-opacity-60",
                  selected
                    ? "bg-white shadow"
                    : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="">
          {Object.values(categories).map((posts, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                "bg-black rounded-xl p-3",
                "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-red-400 ring-white ring-opacity-60"
              )}
            >
              <ul>
                {posts.map(post => (
                  <li key={post.id} className="relative  rounded-md ">
                    <img src={post.path} alt={post.alt} />

                    <a
                      href="#"
                      className={classNames(
                        "absolute inset-0 rounded-md",
                        "focus:z-10 focus:outline-none focus:ring-2 ring-red-400"
                      )}
                    />
                  </li>
                ))}
              </ul>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

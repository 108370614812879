import * as React from "react"
import { useRef } from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "./index.css"
import Seo from "../components/seo"
import NavMenu from "../components/navMenu"
import Tabs from "../components/tabs"

const CprPage = ({ data }) => {
  const searchForm = useRef(null)

  const handleClickEvent = event => {
    event.preventDefault()
    const form = searchForm.current
    navigate("/search", {
      state: { sale: false, entry: `${form["entry"].value}` },
    })
  }

  const handleKeypress = e => {
    if (e.key === "Enter") {
      e.preventDefault()
      navigate("/search", {
        state: { sale: false, entry: e.target.value },
      })
    }
  }

  return (
    <div className="flex flex-col bg-black h-screen justify-between">
      <Seo title="CCI IMIR™" />

      <main>
        <div className="bg-hero-pattern h-96 -mb-100"></div>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1080,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          <div className="pt-16 flex justify-between">
            <div className="">
              <Link to="/">
                <StaticImage
                  src="../images/siyo-logo.png"
                  width={96}
                  quality={100}
                  alt="Si-Yo Music Logo"
                />
                <span> </span>
                <StaticImage
                  src="../images/ficec-logo.png"
                  width={110}
                  quality={100}
                  alt="FICEC Logo"
                />
              </Link>
            </div>

            <div className="flex items-center  ">
              <NavMenu />
            </div>
          </div>

          <div className="text-white text-center font-extrabold pt-14 pb-6 text-2xl sm:text-5xl ">
            Become a Content Provider of Record
          </div>
          <div className="pt-8 flex justify-center">
            <Link className="text-white font-bold" to="/register">
              <button
                className="inline-flex
            justify-items-center
            px-8
            py-2
            text-sm
            font-large
            text-white
            bg-white
            rounded-md
            hover:bg-red-600
            bg-opacity-10
            hover:bg-opacity-800
            focus:outline-none 
            "
              >
                Register
              </button>
              {/* </Menu.Button> */}
              {/* </Menu> */}
            </Link>
          </div>
          <div className="pt-28 pb-4">
            <div>
              {" "}
              <p className="font-medium  text-lg text-center text-white ">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting.
              </p>
            </div>
          </div>
        </div>
      </main>

      <div>
        <div className="flex justify-center bg-black">
          <Tabs />
        </div>
      </div>

      <footer className="bg-black">
        <div className="py-5 text-white flex flex-row justify-center ">
          v1.1.452 © {new Date().getFullYear()}&nbsp;
          <a className="text-white " href="https://www.si-yomusicsociety.org/">
            Si-Yo Music Society Foundation, Inc.
          </a>
        </div>
      </footer>
    </div>
  )
}

export default CprPage
